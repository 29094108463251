<template>
  <div>
    <van-cell-group :title="conf.NAME">
      <van-cell v-for="itm in conf.SONS" :key="itm.ID" :title="itm.NAME">
        <van-checkbox shape="square" v-model="itm.PASS" slot="extra" @click="sonClick" />
      </van-cell>
      <van-field type="textarea" label="问题描述(异常)" :rows="2" v-model="model.DESCRIPTION" />
      <van-field type="textarea" label="问题描述(正常)" :rows="2" v-model="model.NORMAL_MESSAGE" />
      <van-cell-group title="异常照片(最多5张)">
        <van-uploader :after-read="afterRead" v-model="picList" @delete="deleteImg" max-count="5"
          style="margin: 10px 0px 0px 10px" />
      </van-cell-group>
    </van-cell-group>
    <div style="margin: 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
        loading-text="正在提交。。。">确认提交</van-button>
      <br />
      <van-button plain type="default" size="large" icon="cross" block to="/safe">取消返回</van-button>
    </div>
  </div>
</template>
<script>
  import { Toast } from "vant";
  export default {
    name: "scan",
    data() {
      return {
        conf: {},
        model: {
          DESCRIPTION: "",
          PIC_B64: '',
          PIC_BS64: []
        },
        ret: {
          loading: false,
        },
        picList: [],
        confName: ''
      };
    },
    created() {
      this.scan();
    },
    methods: {
      getConfig() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/SAFE/ConfigApi/Get",
          data: { GUID: self.conf.GUID },
          completed(m) {
            let normal = "";
            self.confName = m.DATA.NAME;
            m.DATA.SONS.forEach((o) => {
              o.PASS = true;
              if (normal != "") normal += ",";
              normal += o.NAME;
            });
            self.conf = m.DATA;
            if (normal != "") normal += "正常";
            self.model.NORMAL_MESSAGE = normal;
          },
        });
      },
      scan() {
        let self = this;
        if (this.$route.query.guid) {
          self.conf.GUID = this.$route.query.guid;
          // self.conf.GUID = '8E0BB0DA-3E85-4ECB-B743-BFFB9B69E926'
          self.getConfig();
          return;
        } else {
          this.whale.wxsdk.init(() => {
            self.whale.wxsdk.scanCode(function (res) {
              self.conf.GUID = res;
              self.getConfig();
            });
          });
          // this.conf.GUID="D20AFFC9-0F47-45B2-9250-9CB6734DFE24";
          // this.getConfig();
        }
      },
      afterRead(f) {
        let self = this;
        this.whale.image.compress({
          file: f.file,
          callback(f) {
            self.whale.image.toB64({
              file: f,
              callback(b64) {
                // self.model.PIC_B64 = b64;
                self.model.PIC_BS64.push(b64)
              },
            });
          },
        });
      },
      deleteImg(event, index) {
        let self = this;
        self.model.PIC_BS64.splice(index, 1);
      },
      submit() {
        let self = this;
        this.model.CONF_ID = this.conf.ID;
        this.model.CONF_ERR = "";
        this.model.CONF_NORMAL = "";
        this.model.RESULT = 9;
        this.conf.SONS.forEach((o) => {
          if (!o.PASS) {
            self.model.CONF_ERR += o.ID + ",";
            self.model.RESULT = 1;
          } else {
            self.model.CONF_NORMAL += o.ID + ",";
          }
        });
        if (self.model.RESULT == 1 && self.model.DESCRIPTION == '') {
          Toast.fail('请填写异常描述')
          return
        }
        if (self.model.DESCRIPTION != '') {
          self.model.RESULT = 1;
        }
        if (self.model.RESULT == 1 && this.model.PIC_BS64.length == 0) {
          Toast.fail('请上传异常图片')
          return
        }
        this.whale.remote.getResult({
          url: "/api/Mobile/SAFE/RecApi/Save",
          data: this.model,
          finally() {
            self.ret.loading = false;
          },
          completed(m) {
            self.$dialog
              .confirm({
                title: "提交成功",
                message: m.DATA,
                theme: "round",
                showCancelButton: false,
                confirmButtonText: "关闭",
              })
              .then(() => {
                // 判断是否在微信环境
                const isWeixin = /MicroMessenger/i.test(navigator.userAgent);
                // 判断是否在微信小程序环境
                const isMiniProgram = /miniprogram/i.test(
                  navigator.userAgent.toLowerCase()
                );
                if (isMiniProgram) {
                  self.$router.go(-1);
                } else if (isWeixin) {
                  self.onCancel();
                } else {
                  // self.$router.go(-1);
                  self.$router.push("/safe");
                }
              });
          },
        });
      },
      onCancel() {
        setTimeout(function () {
          document.addEventListener(
            "WeixinJSBridgeReady",
            function () {
              this.WeixinJSBridge.call("closeWindow"); //安卓手机关闭代码
            },
            false
          );
          this.WeixinJSBridge.call("closeWindow"); //苹果手机关闭代码
        }, 300);
      },
      sonClick() {
        let desc = "";
        let normal = "";
        this.conf.SONS.forEach((o) => {
          if (!o.PASS) {
            if (desc != "") desc += ",";
            desc += o.NAME;
          } else {
            if (normal != "") normal += ",";
            normal += o.NAME;
          }
        });
        if (desc != "") desc += "异常";
        this.model.DESCRIPTION = desc;
        if (normal != "") normal += "正常";
        this.model.NORMAL_MESSAGE = normal;
      },
    },
  };
</script>
<style scoped></style>